var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-xs px-3 py-2 rounded-md",class:{
    'bg-gray-200 bg-opacity-50': _vm.syncJob.status === 'init',
    'bg-yellow-600 bg-opacity-5': _vm.syncJob.status === 'syncing'
  }},[_c('div',{staticClass:"font-semibold",class:{ 'text-xs': _vm.type === 'for-map', 'text-sm': _vm.type === 'list' }},[_c('div',{staticClass:"flex gap-1",class:{
        ' text-yellow-600': _vm.syncJob.status === 'init',
        'text-primary relative': _vm.syncJob.status === 'init' || (_vm.syncJob.status === 'ended' && _vm.syncJob.error != true),
        'text-red-600 relative': _vm.syncJob.error == true
      }},[_c('div',[(_vm.syncJob.error == true)?_c('fw-icon-error-warning',{staticClass:"w-5 h-5 -mt-0.5 flex-shrink-0"}):_c('fw-icon-refresh',{staticClass:"w-5 h-5 mr-1 -mt-0.5 flex-shrink-0",class:{ 'animate-spin': _vm.syncJob.status === 'syncing' }})],1),_c('div',[_vm._v(_vm._s(_vm.syncLabel))])])]),_c('div',{class:{ 'flex flex-col': _vm.type === 'card', 'flex gap-5': _vm.type === 'list' }},[(_vm.type === 'list' && !_vm.isGlobal && _vm.syncJob?.employee)?_c('div',{staticClass:"text-sm flex-1 pl-5"},[_c('fw-label',{attrs:{"size":"xs"}},[_vm._v("Detalhes do mapa em sincronização")]),(_vm.syncJob?.employee)?_c('div',[_c('fw-person',{attrs:{"size":"xs","person":_vm.syncJob?.employee,"paddingless":""}})],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"text-xs",class:{ 'sm:w-1/3 border-l pl-5': _vm.type === 'list' && !_vm.isGlobal }},[(_vm.type === 'list')?_c('fw-label',{attrs:{"size":"xs"}},[_vm._v("Executado por")]):_vm._e(),(_vm.syncJob?.user)?_c('div',{staticClass:"my-0.5"},[_c('fw-person',{staticStyle:{"min-height":"29px"},attrs:{"person":_vm.syncJob?.user,"paddingless":"","size":"micro"}})],1):_vm._e(),(_vm.syncJob.ended_at)?_c('div',{staticClass:"text-gray-500"},[_vm._v("Terminado em "+_vm._s(_vm._f("formatDateTime")(_vm.syncJob.ended_at)))]):(_vm.syncJob.started_at)?_c('div',{staticClass:"text-gray-500"},[_vm._v(" Iniciado em "+_vm._s(_vm._f("formatDateTime")(_vm.syncJob.started_at))+" ")]):(_vm.syncJob.inited_at)?_c('div',{staticClass:"text-gray-500"},[_vm._v(" Executado em "+_vm._s(_vm._f("formatDateTime")(_vm.syncJob.inited_at))+" ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }