<template>
  <div class="w-full h-full flex flex-col overflow-x-hidden">
    <div v-if="latestApproved" class="px-3 my-3">
      <button
        class="flex px-4 py-3 gap-3 relative w-full text-left group rounded-lg text-base items-center justify-between"
        :class="{
          'text-primary bg-white shadow-md': mapUserKey == `current`,
          'bg-gray-300 bg-opacity-20 text-gray-600 hover:text-gray-800': mapUserKey != `current`
        }"
        @click="$emit('open', latestApproved, `current`)"
      >
        <div class="flex gap-3 items-center">
          <div>
            <fw-icon-calendar-event class="w-5 h-5 flex-shrink-0" />
          </div>
          <div class="font-bold">Versão atual</div>
          <div class="text-sm font-medium">v.{{ latestApproved?.version }}</div>
        </div>
      </button>
    </div>

    <div v-if="$slots['default']" class="px-3 mb-2" :class="{ 'mt-3': !latestApproved }">
      <slot></slot>
    </div>

    <div class="px-4 flex justify-between items-center">
      <div>
        <fw-heading size="sm" muted>
          <div class="flex gap-1 items-center">
            <div class="flex-1">
              <span>Versões</span>
              <fw-badge v-if="userMaps" inline size="xs" type="simple">{{ userMaps.length }}</fw-badge>
            </div>
          </div>
        </fw-heading>
      </div>
      <div class="-mr-5">
        <BlockUserMapsFilters
          :filters="filters"
          :multifilter="true"
          :applied-filters="appliedFilters"
          @filter-changed="filterChanged"
        />
      </div>
    </div>

    <LoadingPlaceholder v-if="loading" size="lg" class="px-4"></LoadingPlaceholder>
    <div v-if="!loading && userMaps?.length" class="flex flex-1 flex-col gap-2 overflow-y-auto text-sm pb-2">
      <DynamicScroller ref="sidebarList" :items="userMaps" :min-item-size="50" key-field="key">
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.created_at]" :data-index="index">
            <div class="px-3 my-0.5">
              <RecordUserMapVersion
                :item="item"
                :latest-approved="latestApproved"
                :user-map-key="mapUserKey"
                @open="$emit('open', $event)"
              />
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
    <fw-panel-info v-else-if="!loading && !userMaps?.length" type="basic" class="text-center my-5 text-gray-500">
      {{ $t('noMaps') }}
    </fw-panel-info>
    <BlockPagination
      v-if="pagination?.total_pages > 1 && !loading"
      :per-page="pagination?.active_limit ?? 25"
      :total="pagination?.total_items ?? 0"
      :total-pages="pagination?.total_pages ?? 0"
      :current="pagination?.current_page ?? 1"
      @page-changed="pageChanged"
    />
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { MAP_USER_STATUS, MAP_USER_STATUS_COLORS, MAP_USER_VERSION_REASONS } from '@/utils/index.js'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import RecordUserMapVersion from '@/components/records/RecordUserMapVersion'
import BlockUserMapsFilters from '@/components/blocks/BlockUserMapsFilters'

export default {
  components: {
    DynamicScrollerItem,
    DynamicScroller,
    RecordUserMapVersion,
    BlockPagination,
    LoadingPlaceholder,
    BlockUserMapsFilters
  },

  props: {
    year: {
      type: Number,
      required: true
    },
    userKey: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      status: MAP_USER_STATUS,
      statusColor: MAP_USER_STATUS_COLORS,
      versionsMotives: MAP_USER_VERSION_REASONS,
      pagination: {
        current_page: 1,
        total_pages: 1,
        active_limit: 50,
        total_items: 1
      },
      maps: {},
      userMaps: [],
      latestApproved: null,
      loading: false,
      appliedFilters: []
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    mapKey() {
      return this.$route.params.key
    },

    mapUserKey() {
      return this.$route.params.mapUserKey
    },

    language() {
      return this.$store.state.language ?? 'pt'
    },

    filters() {
      return [
        {
          key: 'status',
          label: this.$t('status'),
          options: [
            {
              key: 'reset',
              label: this.$t('all')
            },
            {
              key: 'draft',
              label: this.status.draft[this.language]
            },
            {
              key: 'submitted',
              label: this.status.submitted[this.language]
            },
            {
              key: 'canceled',
              label: this.status.canceled[this.language]
            },
            {
              key: 'approved',
              label: this.status.approved[this.language]
            },
            {
              key: 'declined',
              label: this.status.declined[this.language]
            },
            {
              key: 'pre_approved',
              label: this.status.pre_approved[this.language]
            },
            {
              key: 'outdated',
              label: this.status.outdated[this.language]
            }
          ]
        }
      ]
    }
  },

  created() {
    this.getUrlParams()
    this.getUserMaps()
  },

  methods: {
    getUrlParams() {
      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.p) {
        this.pagination.current_page = parseInt(this.$route.query.p)
      }
    },

    setUrlParams() {
      let query = {}

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      query['p'] = this.pagination.current_page

      this.$router.push({ path: this.$route.path, query: query })
    },

    async getUserMaps() {
      console.log('getUserMaps', { year: this.year, userKey: this.userKey })
      if (!this.year || !this.userKey) return
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getManagerMapUsers(this.mapKey, {
          short_data: true,
          user_key: this.userKey,
          page: this.pagination.current_page,
          limit: this.pagination.active_limit,
          get_latest_versions: true,
          ...utils.setFiltersQuery(this.appliedFilters)

          // // To get all new versions of previous years
          // created_at_start: '2024-01-01',
          // latest_only: this.year < 2024 ? true : false,
        })
        console.log('getUserMaps :>> ', response)
        this.latestApproved = response.latest_versions?.[this.year]
        this.userMaps = response.user_maps
        this.users = response.users
        this.maps = response.maps
        this.pagination = response.pagination
      })

      this.loading = false
      setTimeout(() => {
        if (this.mapUserKey) {
          const index = this.userMaps?.findIndex(map => map.key == this.mapUserKey)
          if (index > -1) this.$refs.sidebarList.scrollToItem(index)
        }
      }, 10)
    },

    pageChanged(page) {
      this.pagination.current_page = page
      this.setUrlParams()
      this.getUserMaps()
    },

    filterChanged(filters) {
      this.appliedFilters = filters
      this.setUrlParams()
      this.getUserMaps()
    }
  }
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "pt": {
    "map": "Mapa de férias",
    "comments": "Comentários",
    "daysList": "Lista de dias",
    "mapVersions": "Versões do mapa",
    "noMaps": "Sem tem mapas associados.",
    "all": "Todos",
    "status": "Estado"
  },
  "en": {
    "map": "Mapa de férias",
    "comments": "Comments",
    "daysList": "Lista de dias",
    "mapVersions": "Versões do mapa",
    "noMaps": "Sem tem mapas associados.",
    "all": "All",
    "status": "Estado"
  }
}
</i18n>
