<template>
  <div
    class="text-xs px-3 py-2 rounded-md"
    :class="{
      'bg-gray-200 bg-opacity-50': syncJob.status === 'init',
      'bg-yellow-600 bg-opacity-5': syncJob.status === 'syncing'
    }"
  >
    <div class="font-semibold" :class="{ 'text-xs': type === 'for-map', 'text-sm': type === 'list' }">
      <div
        class="flex gap-1"
        :class="{
          ' text-yellow-600': syncJob.status === 'init',
          'text-primary relative': syncJob.status === 'init' || (syncJob.status === 'ended' && syncJob.error != true),
          'text-red-600 relative': syncJob.error == true
        }"
      >
        <div>
          <fw-icon-error-warning v-if="syncJob.error == true" class="w-5 h-5 -mt-0.5 flex-shrink-0" />
          <fw-icon-refresh
            v-else
            class="w-5 h-5 mr-1 -mt-0.5 flex-shrink-0"
            :class="{ 'animate-spin': syncJob.status === 'syncing' }"
          />
        </div>
        <div>{{ syncLabel }}</div>
      </div>
    </div>
    <div :class="{ 'flex flex-col': type === 'card', 'flex gap-5': type === 'list' }">
      <div v-if="type === 'list' && !isGlobal && syncJob?.employee" class="text-sm flex-1 pl-5">
        <fw-label size="xs">Detalhes do mapa em sincronização</fw-label>
        <div v-if="syncJob?.employee">
          <fw-person size="xs" :person="syncJob?.employee" paddingless> </fw-person>
        </div>
      </div>
      <div class="text-xs" :class="{ 'sm:w-1/3 border-l pl-5': type === 'list' && !isGlobal }">
        <fw-label v-if="type === 'list'" size="xs">Executado por</fw-label>
        <div v-if="syncJob?.user" class="my-0.5">
          <fw-person :person="syncJob?.user" paddingless size="micro" style="min-height: 29px" />
        </div>
        <div v-if="syncJob.ended_at" class="text-gray-500">Terminado em {{ syncJob.ended_at | formatDateTime }}</div>
        <div v-else-if="syncJob.started_at" class="text-gray-500">
          Iniciado em {{ syncJob.started_at | formatDateTime }}
        </div>
        <div v-else-if="syncJob.inited_at" class="text-gray-500">
          Executado em {{ syncJob.inited_at | formatDateTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    syncJob: {
      type: Object,
      required: true
    },
    context: {
      type: String,
      default: 'sync-rights'
    },
    type: {
      type: String,
      default: 'card' // card
    }
  },

  data() {
    return {
      statusLabel: {
        init: {
          'sync-permissions': 'A sincronização de permissões desta versão do mapa irá iniciar em breve',
          'sync-rights': 'A sincronização de direitos desta versão do mapa irá iniciar em breve',
          'sync-days': 'A sincronização de dias desta versão do mapa irá iniciar em breve'
        },
        ended: {
          'sync-permissions': 'Sincronização de permissões terminou',
          'sync-rights': 'Sincronização de direitos terminou',
          'sync-days': 'Sincronização de dias terminou'
        },
        syncing: {
          'sync-permissions': 'Sincronização de permissões em curso',
          'sync-rights': 'Sincronização de direitos em curso',
          'sync-days': 'Sincronização de dias em curso'
        },
        error: {
          'sync-permissions': 'Sincronização de permissões falhou',
          'sync-rights': 'Sincronização de direitos falhou',
          'sync-days': 'Sincronização de dias falhou'
        }
      }
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },

    isGlobal() {
      return !this.syncJob?.params?.employee_numbers?.length
    },

    syncLabel() {
      if (this.syncJob.error == true) return this.statusLabel.error[this.context]
      return this.statusLabel[this.syncJob.status][this.context]
    }
  }
}
</script>
